.opcionesArtefacto{
    padding: 0 !important;
    
}

.opcionesArtefacto #demo-simple-select{
    border: none;
    padding-right: 15px;
    height: 10px;
    min-height: 0;
    padding: 2px 9px;
}

.opcionesArtefacto svg{
    right: 2px;
}

.opcionesArtefacto fieldset{
    display: none;

}

.opcionesArtefacto div {
    /* border: 1px solid #000; */
}

.css-sgoict-MuiCardHeader-action{
    margin-right: -13px;
}

.stackBotoonCards .selectStackBotoonCards fieldset, .stackBotoonCards .div  {
   
    border: none;

}

.stackBotoonCards .selectStackBotoonCards > div {
    padding: 0;
}

