.login .logo{
    text-align: center;
    color: #9a999e;
    font-size: 40px;
    letter-spacing: -3px;
}

.login .logo span{
    color: #4e71a5;
}

.login > div:first-child{
    background-color: #42CCCC;;
}

.login .button-loging{
    color: #f5faf8;
    /* background: #5c9298; */
    border-radius: 8px;
    padding: 10px;
    border: none;
}

.login .button-loging:hover{
    background: #5c9298;
}

.login .button-create-cuenta{
    background-color: #121212 ;
    margin-left: 20px;
    margin-right: 20px;
}

.button-olvidastes-contrasenia{
    background-color: none;
    color: #121212 !important;
}

.login small{
    margin-top: 5px !important;
    font-size: x-small !important;
    font-style: italic;
}

.login .MuiChip-labelSmall {
    
}



