body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.MuiContainer-root, .MuiGrid-root{
  height: 100%;
}

body{
  background-color: #42CCCC;;
}

.textAlingCenter{
  text-align: center;
}

.margin-top-0{ margin-top: 0px !important;}
.margin-top-5{ margin-top: 5px !important;}

.margin-top-10{margin-top: 10px !important;}

.capitalize{
  text-transform: capitalize !important;
}


.noPaddings{
  padding: 0 !important;
}


.noMargings{
  margin: 0 !important;
}


.colorRed{
  color: rgb(255, 6, 6);
}

.MuiBox-root.css-a58nir{
  width: 100%;
}

div{
 /*  border: #000 1px solid !important; */
}



.mobile-none{
  display: none;
}

@media (min-width: 600px) {
  .mobile-none{
    display: block;
  }
  
}
@media (min-width: 900px) {
  .mobile-none{
    display: block;
  }
 
}




.barWorkflow div{

  /* background: #b8b8b8 !important;
  border: 1px solid #000; */

}

.texTruquoiseBazooit{
  color: #a70404;
}

.no-boder-select fieldset{

  border: none !important;

}

.no-label label{
 /*  display: none; */

}

::-ms-reveal {
  display: none;
}


