.css-15oklta-MuiButtonBase-root-MuiFab-root{
    background-color: #4e71a5 !important;
}

.MuiListItem-root{
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.css-1k23hlb-MuiButtonBase-root-MuiButton-root{
    border: none !important;
    color: #4e71a5 !important;
    background-color: #afe3d4 !important;
    padding: 0px 7px !important;
}

.dasboard .WorkFlow{
   /*  height: 100%; */

}

.dasboard{
   /*  margin-top: 7px;
    overflow: auto;
    height: -webkit-fill-available !important;
    padding: 0px 0px 100px 0px;*/
    background: #f1f1f1; 
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0px;
}

