
/* 
.barWorkflow > div {padding-top: 0px !important;  padding-bottom: 0px;}
.barWorkflow > div:first-child {padding-top: 30px !important;}

@media (min-width: 600px) {
    .barWorkflow > div {padding-top: 18px !important; padding-bottom: 2px;}
    .barWorkflow > div:first-child {padding-top: 18px !important;}

}
@media (min-width: 900px) {
    .barWorkflow > div {padding-top: 19px !important;}
    .barWorkflow > div:first-child {padding-top: 19px !important;}

 
} */

.swiper-3d .swiper-slide-shadow-left, .swiper-3d .swiper-slide-shadow-right {
    background-image:none;
    background: #f1f1f1ab;
}

.mySwiper{
    padding-right: 8px;
    padding-bottom: 5px;
    margin-top: 3px;

}

.swiper {
    left: 0;
    right: 0;
    top: 96px;
    bottom: 0;
    position: absolute;
}

/* width */
::-webkit-scrollbar {
    width: 3px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

