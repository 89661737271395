.fechaDasboard label{
    display: none !important;
    border: 1px solid rgb(244, 1, 1);
}

.fechaDasboard input{
    padding-top: 6px;
}


.AppBar .css-g21yqb-MuiTypography-root{
    padding: 0px;   
}

.AppBar .ubucacion{
    padding-left: 20px;
    display: block;
    line-height: 1 !important;
    margin-top: 10px;
}

.AppBar .ubucacion small{
    font-size: 12px;
}

.AppBar .MuiToolbar-root{
    background-color: #42CCCC;
    min-height: 50px;

}

.logo > img{
    max-height: 30px;
}



.logo span{
    color: #9a999e;
}

.AppBar .SelectAdminitrador .MuiInputBase-root{
    display: inline;
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    padding: 0px;
    
}

.AppBar .SelectAdminitrador svg{
    right: 3px;
}

.AppBar .search{
    display: flex;
    padding-left: 0 !important;
    justify-content: flex-end;
}

.text-alig{
    justify-content: flex-end !important;
}
