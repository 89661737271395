.appBar .MuiPaper-root{
    background-color: #4e71a5 !important;
    color: #ffffff !important;
}

.appBar .tituloRows{
    margin-left: 15px !important;
}

.appBar .css-cveggr-MuiListItemIcon-root{
    min-width: 35px !important;  
}